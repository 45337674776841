export const orderOptionsData = {
  isCarPass: "Пропуск в ТТК СК",
  isCashCollection: "Инкассация",
  isTentTop: "Растентовка верх",
  isBathrobe: "Халат",
  isForwarding: "Экспедирование",
  isTentLateral: "Растентовка бок",
  isSanProcessing: "Сан. обработка",
  isDriverLoader: "Водитель грузчик",
  isTailLift: "Гидролифт",
  isMedBook: "Мед. книжка"
};
