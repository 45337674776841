<template>
  <div class="form-checkbox">
    <input
      :class="{ invalid: isInvalid }"
      :id="id"
      :type="type"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :value="value"
      :checked="checked"
      @change="change"
    />
    <label v-if="label" :class="{ invalid: isInvalid }" :for="id">{{
      label
    }}</label>
  </div>
</template>

<script>
export default {
  name: "FormCheckbox",
  props: {
    value: {
      type: [String, Number, Array],
      default: ""
    },
    type: {
      type: String,
      default: "checkbox"
    },
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "checkbox-" + Math.random()
    };
  },
  methods: {
    change(event) {
      this.$emit("change", event.target.value);
    }
  }
};
</script>
