<template>
  <section>
    <div ref="openOrderDropRef">
      <button
        class="main__orders--add"
        :class="status > 3 ? `disabled` : ``"
        @click="openOrderDropHandler"
        :disabled="status > 3"
      >
        <img src="../../assets/images/button_plus.svg" alt="Плюс" />
        НОВЫЙ ЗАКАЗ
      </button>
      <ul v-if="open" class="main__orders--list">
        <li @click="addNewOrderHandler(1)">Заказать грузовое ТС</li>
        <li @click="addNewOrderHandler(2)">Заказать грузчика</li>
        <li @click="addNewRequest">Загрузить свою заявку</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "AddOrder",
  props: {
    status: { type: [Number, String], default: "" }
  },
  destroyed() {
    if (!this.open) {
      document.removeEventListener("click", this.dropOrderDropHandler);
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    // Открытия списка создания нового заказа
    openOrderDropHandler() {
      this.open = !this.open;
      document.addEventListener("click", this.dropOrderDropHandler);
    },
    // Отправляем тип заказа и закрываем Дроп
    addNewOrderHandler(value) {
      this.open = false;
      this.$emit("addNewOrderDrop", value);
    },
    // Клик не по элементу второго окна
    dropOrderDropHandler(e) {
      let el = this.$refs.openOrderDropRef;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.open = false;
      }
    },
    // Добавляем новую заявку
    addNewRequest() {
      this.open = false;
      this.$emit("getSecondModal", true);
    }
  }
};
</script>

<style lang="scss">
.main__orders {
  @include flex;
  @include flex-content-start;
  // border-left: 1px solid $color-grey-light;
  // margin: 10px 0 31px 0;
  left: $main-margin-left;
  margin-top: 12px;
  padding-left: $basic-margin-left;
  position: fixed;
  top: 0;
  // width: 100%;
  z-index: 110 !important;

  &--add {
    @include flex;
    @include flex-content-start;
    background-color: $color-yellow-dark;
    border: none;
    border-radius: 8px;
    color: $color-basic;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    height: 40px !important;
    position: relative;
    width: 232px !important;

    &:hover {
      background: $color-grey-light;
      img {
        @include rotation-right;
      }
    }

    img {
      @include rotation-left;
      margin: 0 31px 0 5px;
    }

    &.disabled {
      background-color: $color-grey-light;
      cursor: not-allowed;
    }
  }

  &--list {
    background: $color-white;
    border: 1px solid $color-grey-border;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    list-style: none;
    position: absolute;
    top: 42px;
    width: 232px;

    li {
      @include flex;
      @include flex-content-start;
      color: $color-black;
      cursor: pointer;
      font-size: 14px;
      margin: 2px;
      padding: 10px 22px;

      &:hover {
        background: $color-green-light;
      }
    }
  }

  &--link {
    color: $color-black;
    cursor: pointer;
    font-size: 16px;
    margin-left: 24px;
    text-decoration: underline;
  }
}
</style>
