export default {
  v: "4.7.1",
  fr: 30,
  ip: 0,
  op: 120,
  w: 1000,
  h: 1000,
  nm: "pulse",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape Layer 8",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 120,
              s: [100],
              e: [0]
            },
            { t: 209 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: 120,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 210 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 120,
      op: 120.30303030303,
      st: 120,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 7",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 90,
              s: [100],
              e: [0]
            },
            { t: 179 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: 90,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 180 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 90,
      op: 120,
      st: 90,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 6",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 60,
              s: [100],
              e: [0]
            },
            { t: 149 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: 60,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 150 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 60,
      op: 120,
      st: 60,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 5",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 30,
              s: [100],
              e: [0]
            },
            { t: 119 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: 30,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 120 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 30,
      op: 120,
      st: 30,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 4",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 0,
              s: [100],
              e: [0]
            },
            { t: 89 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: 0,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 90 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 3",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: -30,
              s: [100],
              e: [0]
            },
            { t: 59 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: -30,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 60 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 0,
      op: 120,
      st: -30,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Shape Layer 2",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: -60,
              s: [100],
              e: [0]
            },
            { t: 29 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: -60,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 30 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 0,
      op: 120,
      st: -60,
      bm: 0,
      sr: 1
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Shape Layer 1",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: -90,
              s: [100],
              e: [0]
            },
            { t: -1 }
          ]
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              n: [
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167",
                "0p833_0p833_0p167_0p167"
              ],
              t: -90,
              s: [1, 1, 100],
              e: [100, 100, 100]
            },
            { t: 0 }
          ]
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [1000, 1000] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse"
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.3568627450980392, 0.9764705882352941, 0.4, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill"
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group"
        }
      ],
      ip: 0,
      op: 120,
      st: -90,
      bm: 0,
      sr: 1
    }
  ]
};
